<script lang="ts" setup>
  import { getBanners } from '~/services/modules/movies.service';
  const HeaderSlider = defineAsyncComponent(() => import('~/components/sliders/header/HeaderSlider.vue'));
  const HeaderSkeleton = defineAsyncComponent(() => import('~/components/sliders/header/HeaderSkeleton.vue'));

  const { data: sliderItems, isFetching: pending, suspense } = getBanners();
  onServerPrefetch(async () => await suspense());
</script>

<template>
  <section class="home-slider breakout">
    <header-slider v-if="sliderItems" :items="sliderItems ?? []" />
    <header-skeleton v-else />
  </section>
</template>

<style lang="scss">
  .home-slider {
    height: 770px;
    @media (max-width: $retina) {
      height: auto;
    }
  }
</style>
